



































































import { defineComponent } from '@vue/composition-api'
import IconGrade from '@/components/utils/IconGrade.vue'
import { useI18n } from 'vue-composable'
import { toReadableNumber, vipAmountByCountry } from '@/helpers'

export default defineComponent({
  components: {
    IconGrade
  },
  setup () {
    const { $t } = useI18n()
    return {
      $t,
      toReadableNumber,
      vipAmountByCountry
    }
  }
})
