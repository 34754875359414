














import { computed, defineComponent, reactive, toRefs } from '@vue/composition-api'
import GradeLevel from '@/components/GradeLevel.vue'
import GradeBenefits from '@/components/GradeBenefits.vue'
import GradeInformation from '@/components/GradeInformation.vue'
import AskQuestions from '@/components/AskQuestions.vue'
import BottomNotice from '@/components/BottomNotice.vue'
import store from '@/store'
import { useI18n } from 'vue-composable'
import { sendToApp } from '@/helpers'
import { IS_LOGGING } from '@/data/constants'

interface State {
  [key: string]: any;
}

export default defineComponent({
  components: {
    GradeLevel,
    GradeBenefits,
    GradeInformation,
    AskQuestions,
    BottomNotice
  },
  setup () {
    const { $t } = useI18n()

    const state = reactive({
      $t,
      text: computed(() => {
        const countries = {
          KR: $t('Benefit.paragraph.TermsContent.Vip_KR').value,
          default: $t('Benefit.paragraph.TermsContent.Vip_Sgid').value
        }
        return countries[store.getters['country/$currentSourceCountryCode'] as keyof typeof countries] ||
          countries.default
      }),
      helps: [
        {
          title: $t('Benefit.question.VIPLevel.Global_1').value,
          content: [$t(`Benefit.answer.VIPLevel.${store.getters['country/$currentSourceCountryCode']}_1`).value]
        },
        {
          title: $t('Benefit.question.VIPLevel.Global_2').value,
          content: [$t('Benefit.answer.VIPLevel.Global_2').value]
        },
        {
          title: $t('Benefit.question.VIPLevel.Global_3').value,
          content: [$t('Benefit.answer.VIPLevel.Global_3').value]
        }
      ],
      notice: computed(() => {
        return {
          title: $t('Event.footer.termsContents.title_foryou').value,
          text: state.text
        }
      })
    }) as State

    const useAmplitudeLogging = () => {
      const amplitudeProperties = {
        level: store.getters['auth/gradeLabel']
      }
      sendToApp('view_myVip', amplitudeProperties, IS_LOGGING)
    }

    useAmplitudeLogging()

    return {
      ...toRefs(state)
    }
  }
})
