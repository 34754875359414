































































import { defineComponent } from '@vue/composition-api'
import store from '@/store'
import IconGrade from '@/components/utils/IconGrade.vue'
import { useI18n } from 'vue-composable'
import { discountRate, toReadableNumber, vipAmountByCountry } from '@/helpers'

export default defineComponent({
  components: {
    IconGrade
  },
  setup () {
    const { $t } = useI18n()

    const {
      getters
    } = store

    return {
      $t,
      discountRate,
      toReadableNumber,
      vipAmountByCountry,
      $getPid: getters['auth/$getPid'],
      gradeLabel: getters['auth/gradeLabel'],
      isDomesticSource: getters['country/isDomesticSource']
    }
  }
})
